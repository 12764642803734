import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Helmet from 'react-helmet'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Layout from '../components/layout'

const Impressum = ({ location }) => (
  <Layout location={location}>
    <Helmet title="Impressum" />
    <Container className="py-lg-5 mt-5 mb-5">
      <Row className="align-items-center pt-5 pt-lg-7">
        <Col>
          <h1 className="text-uppercase">Impressum</h1>
          <h6>gem. §5 E-Commerce Gesetz</h6>
        </Col>
      </Row>
      <Row className="py-5 pt-lg-7 pb-lg-5">
        <Col md="4">
          <h6 className="text-uppercase">Adresse</h6>
          <address>
            Cristiana Campanile<br />
            Echerntalweg 222<br />
            4830 Hallstatt<br />
            Österreich
          </address>
        </Col>
        <Col md="4">
          <h6 className="text-uppercase">Telefon</h6>
          <p>+43 664 20 650 83</p>
        </Col>
        <Col md="4">
          <h6 className="text-uppercase">Email</h6>
          <p>
            <a href="mailto:campanile@hallstatt-guides.at">campanile@hallstatt-guides.at</a>
          </p>
        </Col>
        <Col className="mt-5">
          <h6 className="text-uppercase">Design und Umsetzung Website</h6>
          <p>
            <OutboundLink href="https://www.schmidleithner.at">schmidleithner.at</OutboundLink> - Software Engineering
          </p>

          <h6 className="text-uppercase mt-5">Bildquellen</h6>
          <p>
            <ul>
              {/*<li>Hallstatt - Startseite - Willian Justen de Vasconcellos</li>*/}
              <li>Hallstatt - Startseite/Touren - Julian Elliott Photography</li>
              <li>Hallstatt - Touren - Yeo Khee</li>
              <li>TVB Ausseerland - Salzkammergut-Griese</li>
              <li>STMG- OÖ Tourismus</li>
              <li>WTG</li>
              <li>brainpark Schloss Orth</li>
              <li>Cristiana Campanile</li>
            </ul>
          </p>
        </Col>
      </Row>
      <Row className="pb-7">
        <Col className="text-justify">
          <h2 className="mb-3">Rechtlicher Hinweis</h2>
          <p className="text-justify">Die Inhalte dieser Website werden ständig aktualisiert und geprüft. Trotz aller Sorgfalt kann keinerlei Gewähr für die Aktualität, Vollständigkeit und Richtigkeit der auf dieser Webseite bereitgestellten Informationen übernommen werden. Eine Haftung oder Garantie hierfür kann daher nicht übernommen werden. Gleiches gilt auch für alle anderen Webseiten, auf die mittels eines Hyperlinks von dieser Webseite verwiesen wird. Für den Inhalt der Webseiten, die aufgrund einer solchen Verbindung erreicht werden können, sind wir nicht verantwortlich. Daher erklären wir ausdrücklich, dass zum Zeitpunkt der Linksetzung die verlinkte Webseite frei von illegalen Inhalten war.</p>

          <p>
            Wir behalten uns das Recht vor, Ergänzungen, Änderungen oder Löschungen der auf dieser Webseite bereitgestellten Informationen jederzeit und ohne Ankündigung vorzunehmen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
          </p>

          <p>Inhalte und Struktur der Webseite sind urheberrechtlich geschützt. Die Vervielfältigung von Informationen oder Daten, insbesondere die Verwendung von Texten, Textteilen, Soundfiles oder Bildmaterial bedarf der vorherigen schriftlichen Zustimmung des Herausgebers.</p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default Impressum